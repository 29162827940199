export const darkTheme = {
  primary: "rgb(29, 33, 37)",
  projectColour: "rgb(22, 26, 29)",
  cardBG: "rgb(29, 33, 37)",
  cardBGHover: "#22262a",
  fontColour: "rgba(182, 194, 207)",
  borderLineColour: "rgba(255, 255, 255, 0.13)",
  BorderMenuBG: "rgb(29, 33, 37)",
  projectBG: "rgba(255, 255, 255, 0.16)",
  searchBorder: "1px solid #738496",
  searchInputBg: "#22272b",
  searchPlaceHolderFontColour: "#8c9caa",
  searchInputBgHover: "#282e32",
  searchOutlineColour: "2px solid #85b3e7",
  activeBorder: "2px solid rgba(143,180,230,255)",
  modalBg: "rgb(40, 46, 50)",
  modalInputBg: "#22272b",
  borderforModal: "1px solid rgba(55, 64, 73, 255)",
  borderforNotificationContainer: "none",
  navbarFontColour: "#9FADBC",
  navbarButtonBG: "#579DFF",
  navbarButtonBGHover: "#85B8FF",
  memberMenuFontColor: "#B6C2CF",
  memberMenuHoverBg: "#323940",
  themeColor: "rgba(182, 194, 207,0.5)",
  themeActiveColor: "#579DFF",
  themeActiveBG: "#09326C",
  themeActiveBorder: "3px solid #579dff",
  navbarHoverButton: "#333c44",
  navbarActiveButton: "#1c2b41",
  navbarActiveFontColour: "#579dff",
  projectsBgHover: "#f1f2f4",
  projectsIconHover: "#e4e6e9",
  submitButtonHover: "#0055cc",
  borderforSideBar: "rgba(40,47,53,255)",
  sideBarFontColour: "#44546F",
  fontColourDark: "#182a4e",
  IconEditBg: "#f1f2f4",
  IconEditBGHover: "rgba(221,223,228,255)",
  backlogBgHover: "#f1f2f4",
  backlogBgActive: "#e9f2ff",
  checkboxCheckedBg: "#0b66e4",
  statusColourGrey: "#091e420f",
  statusColourBlue: "#1d7afc",
  statusColourGreen: "#22a06b",
};
export const lightTheme = {
  primary: "rgba(255,255,255,255)",
  projectColour: "rgba(247,248,249,255)",
  cardBG: "#ffffff",
  cardBGHover: "#e9ebee",
  fontColour: "rgb(66, 82, 110)",
  borderLineColour: "rgba(24, 27, 30, 0.1)",
  BorderMenuBG: "#FAFBFC",
  projectBG: "rgba(24, 27, 30, 0.1)",
  searchBorder: "1px solid #8590a2",
  searchInputBg: "#fff",
  searchPlaceHolderFontColour: "#616f86",
  searchInputBgHover: "#f7f8f9",
  searchOutlineColour: "2px solid #3e8bf8",
  activeBorder: "2px solid #388bff",
  modalBg: "#fff",
  modalInputBg: "rgb(244, 245, 247)",
  borderforModal: "1px solid rgba(221,223,228,255)",
  borderforNotificationContainer: "1px solid #dfe1e6",
  NewProjectModalFontColour: "rgb(23, 43, 77)",
  navbarFontColour: "#495a73",
  navbarButtonBG: "#0C66E4",
  navbarButtonBGHover: "#0055CC",
  memberMenuFontColor: "#172b4d;",
  memberMenuHoverBg: "#f1f2f4",
  themeColor: "rgba(66, 82, 110,0.5)",
  themeActiveColor: "#0c66e4",
  themeActiveBG: "#E9F2ff",
  themeActiveBorder: "3px solid #0052cc",
  navbarHoverButton: "#091e4224",
  navbarActiveButton: "#E9F2FF",
  navbarActiveFontColour: "#0c66e4",
  projectsBgHover: "#f1f2f4",
  projectsIconHover: "#e4e6e9",
  submitButtonHover: "#0055cc",
  borderforSideBar: "rgba(238,239,241,255)",
  sideBarFontColour: "#44546F",
  fontColourDark: "#182a4e",
  IconEditBg: "#f1f2f4",
  IconEditBGHover: "rgba(221,223,228,255)",
  backlogBgHover: "#f1f2f4",
  backlogBgActive: "#e9f2ff",
  checkboxCheckedBg: "#0b66e4",
  statusColourGrey: "#091e420f",
  statusColourBlue: "#1d7afc",
  statusColourGreen: "#22a06b",
};
